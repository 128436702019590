const classNames = require('classnames').default;

// #region Gatsby Methods
// TODO: Research this again - do we need to keep this 'hack' around?
// Many issues with fragment/hash linking, solution below derived from:
// https://github.com/gatsbyjs/gatsby/issues/8413
// https://github.com/gatsbyjs/gatsby/issues/5558

/** @param {import('gatsby').RouteUpdateArgs} args */
exports.onRouteUpdate = ({ location: { hash } }) => {
	if (!hash) {
		// No hash, no redirecting.
		return;
	}

	// We expect either '#an_id' or '#an_id.a_child_class'
	/** @type {string | null} */
	let target = hash.split('#')?.[1]?.split('.')?.[0] ?? null;
	/** @type {string | null} */
	let subTarget = hash.split('.')?.[1] ?? null;

	if (!!target || !!subTarget) {
		let success = false;
		let attempts = 0;
		const intervalId = setInterval(() => {
			if (!success && attempts < 10) {
				attempts++;
				success = doScrolling(hash, target, subTarget);
			} else {
				clearInterval(intervalId);
			}
		}, 1000);
	}
};
// #endregion

// #region Helper Funcs
/**
 * @param {string} hash
 * @param {string} target
 * @param {string} [subTarget]
 * @returns {boolean} A value indicating whether the scrolling was successful
 */
function doScrolling(hash, target, subTarget) {
	if (!!subTarget) {
		// We need to find and open the target.
		const foundTarget = document.getElementById(target);
		if (!foundTarget) {
			return false;
		}

		// Attempt to find and click the expandable button.
		const button = foundTarget.getElementsByClassName('expandable-content-block-button-text')?.[0];
		if (!button) {
			return false;
		}
		// @ts-ignore This is easier to do in typescript lol 'Element' doesn't have click. This is a HTMLButtonElement.
		button.click();

		// Set timeout to scroll to the innards.
		setTimeout(() => doScrolling(hash, subTarget), 50);
		return true;
	} else if (!!target) {
		return scrollEleIntoView(target);
	}
}

/**
 * @param {string} id
 * @param {ScrollIntoViewOptions} [scrollOptions]
 * @returns {boolean} A value indicating whether the finding and scrolling was a success.
 */
function scrollEleIntoView(id, scrollOptions = { behavior: 'smooth', block: 'center', inline: 'center' }) {
	const ele = document.getElementById(id);
	if (!ele) {
		return false;
	}

	ele.ariaLabel = classNames(ele.ariaLabel, 'This is a highlighted element.');
	ele.scrollIntoView(scrollOptions);
	return true;
}

// #endregion
